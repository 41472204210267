// src/components/PostageCalculator.js
import React, { useState } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  Typography,
  Grid,
  IconButton,
  Collapse,
  FormControlLabel,
  Tooltip
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';

const PostageCalculator = () => {
  const [formData, setFormData] = useState({
    postageType: 'letter',
    pounds: '',
    ounces: '',
    length: '',
    width: '',
    height: '',
    originZip: '',
    destinationZip: '',
    requiresGround: false,
    contents: {
      crematedRemains: false,
      liveAnimals: false,
      dayOldPoultry: false,
      hazardousMaterials: false,
    },
  });

  const [isContentsExpanded, setIsContentsExpanded] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      if (name in formData.contents) {
        setFormData((prev) => ({
          ...prev,
          contents: { ...prev.contents, [name]: checked },
        }));
      } else {
        setFormData((prev) => ({ ...prev, [name]: checked }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleReset = () => {
    setFormData({
      postageType: 'letter',
      pounds: '',
      ounces: '',
      length: '',
      width: '',
      height: '',
      originZip: '',
      destinationZip: '',
      requiresGround: false,
      contents: {
        crematedRemains: false,
        liveAnimals: false,
        dayOldPoultry: false,
        hazardousMaterials: false,
      },
    });
    setIsContentsExpanded(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add calculation logic here
    console.log(formData);
  };

  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="calc(100vh - 64px - 64px)" // Adjust the height to ensure no overlap with header and footer
        p={3}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          p={3}
          boxShadow={3}
          bgcolor="white"
          width="100%"
          maxWidth="600px"
        >
          <Typography variant="h4" gutterBottom>
            Postage Calculator
          </Typography>
          <Box display="flex" alignItems="center" mb={2} mt={3}>
            <Typography variant="h6">
              Shipping Route
            </Typography>
            <Tooltip title="Select the type of postage for your parcel.">
              <InfoIcon style={{ marginLeft: 4 }} />
            </Tooltip>
          </Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <TextField
                label="Origin ZIP Code"
                type="text"
                name="originZip"
                value={formData.originZip}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={2} container justifyContent="center">
              <ArrowForwardIcon />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Destination ZIP Code"
                type="text"
                name="destinationZip"
                value={formData.destinationZip}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Box display="flex" alignItems="center" mt={3} >
            <Typography variant="h6">
              Postage Type
            </Typography>
            <Tooltip title="Select the type of postage for your parcel.">
              <InfoIcon style={{ marginLeft: 4 }} />
            </Tooltip>
          </Box>
          <FormControl fullWidth margin="normal">
            <Select
              name="postageType"
              value={formData.postageType}
              onChange={handleChange}
              required
            >
              <MenuItem value="letter">Letter</MenuItem>
              <MenuItem value="largeEnvelope">Large Envelope</MenuItem>
              <MenuItem value="package">Package</MenuItem>
            </Select>
          </FormControl>

          <Box display="flex" alignItems="center" mt={3} mb={2}>
            <Typography variant="h6">
              Parcel Weight
            </Typography>
            <Tooltip title="Enter the weight of your parcel in pounds and ounces.">
              <InfoIcon style={{ marginLeft: 4 }} />
            </Tooltip>
          </Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <TextField
                label="Pounds"
                type="number"
                name="pounds"
                value={formData.pounds}
                onChange={handleChange}
                inputProps={{ min: 0 }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={2} container justifyContent="center">
              <AddIcon />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Ounces"
                type="number"
                name="ounces"
                value={formData.ounces}
                onChange={handleChange}
                inputProps={{ min: 0 }}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          
          <Box display="flex" alignItems="center" mt={3} mb={2}>
            <Typography variant="h6">
              Parcel Dimensions
            </Typography>
            <Tooltip title="Enter the dimensions of your parcel in inches.">
              <InfoIcon style={{ marginLeft: 4 }} />
            </Tooltip>
          </Box>
          <Grid container spacing={1} alignItems="center" justifyContent="center">
            <Grid item xs={3}>
              <TextField
                label="Length"
                type="number"
                name="length"
                value={formData.length}
                onChange={handleChange}
                inputProps={{ min: 0 }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={1}>
              <Typography align="center">X</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Width"
                type="number"
                name="width"
                value={formData.width}
                onChange={handleChange}
                inputProps={{ min: 0 }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={1}>
              <Typography align="center">X</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Height"
                type="number"
                name="height"
                value={formData.height}
                onChange={handleChange}
                inputProps={{ min: 0 }}
                required
                fullWidth
              />
            </Grid>
          </Grid>

          <Box display="flex" alignItems="center" style={{ marginTop: '20px' }}>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography variant="h6">
              Restricted Items
            </Typography>
            <Tooltip title="Select the type of postage for your parcel.">
              <InfoIcon style={{ marginLeft: 4 }} />
            </Tooltip>
          </Box>
            <IconButton
              onClick={() => setIsContentsExpanded(!isContentsExpanded)}
              aria-expanded={isContentsExpanded}
              aria-label="show contents"
            >
              {isContentsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          <Collapse in={isContentsExpanded}>
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.contents.crematedRemains}
                        onChange={handleChange}
                        name="crematedRemains"
                      />
                    }
                    label="Cremated Remains"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.contents.liveAnimals}
                        onChange={handleChange}
                        name="liveAnimals"
                      />
                    }
                    label="Live Animals"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.contents.dayOldPoultry}
                        onChange={handleChange}
                        name="dayOldPoultry"
                      />
                    }
                    label="Day-old Poultry"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.contents.hazardousMaterials}
                        onChange={handleChange}
                        name="hazardousMaterials"
                      />
                    }
                    label="Hazardous Materials"
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </Collapse>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.requiresGround}
                onChange={handleChange}
                name="requiresGround"
              />
            }
            label="Requires Ground Transportation"
            style={{ marginTop: '20px' }}
          />
          <Box display="flex" justifyContent="flex-start" mt={2}>
            <Button variant="contained" color="primary" type="submit">
              Calculate
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleReset}
              style={{ marginLeft: '10px' }}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default PostageCalculator;