// src/components/Footer.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box mt={5} p={3} bgcolor="text.secondary" color="white" textAlign="center">
      <Typography variant="body1">
        &copy; {new Date().getFullYear()} ShipRateCalculator. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;